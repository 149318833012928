<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditSetorModal
      v-if="showCreateEditSetorModal"
      :setorId="setorIdSelected"
      @closeModal="closeCreateEditSetorModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Setores</h5>
        <div v-if="getModuleUserByCode('0.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditSetor()"
          >
            Novo Setor
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListSetores :setores="setores" @editSetor="createEditSetor" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditSetorModal from '@/components/administration/setores/CreateEditSetorModal.vue'
import ListSetores from '../../../components/administration/setores/ListSetores.vue'

export default {
  name: 'Setores',

  components: {
    Layout,
    PageHeader,
    CreateEditSetorModal,
    LoadingData,
    ListSetores,
  },

  data() {
    return {
      title: 'Setores',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Unidades',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditSetorModal: false,
      setores: null,
      setorIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getSetores() {
      try {
        this.setores = await this.$store.dispatch('getSetores')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditSetor(setorId) {
      this.setorIdSelected = setorId || null
      this.showCreateEditSetorModal = true
    },

    closeCreateEditSetorModal(loadListSetores) {
      if (loadListSetores) {
        this.getSetores()
      }
      this.showCreateEditSetorModal = false
    },
  },

  mounted() {
    this.getSetores()
  },
}
</script>
