<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditUnidadeModal
      v-if="showCreateEditUnidadeModal"
      :unidadeId="unidadeIdSelected"
      @closeModal="closeCreateEditUnidadeModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Unidades</h5>
        <div v-if="getModuleUserByCode('0.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditUnidade()"
          >
            Nova Unidade
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListUnidades :unidades="unidades" @editUnidade="createEditUnidade" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditUnidadeModal from '@/components/administration/unidades/CreateEditUnidadeModal.vue'
import ListUnidades from '../../../components/administration/unidades/ListUnidades.vue'

export default {
  name: 'Unidades',

  components: {
    Layout,
    PageHeader,
    CreateEditUnidadeModal,
    LoadingData,
    ListUnidades,
  },

  data() {
    return {
      title: 'Unidades',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Unidades',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditUnidadeModal: false,
      unidades: null,
      unidadeIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditUnidade(unidadeId) {
      this.unidadeIdSelected = unidadeId || null
      this.showCreateEditUnidadeModal = true
    },

    closeCreateEditUnidadeModal(loadListUnidades) {
      if (loadListUnidades) {
        this.getUnidades()
      }
      this.showCreateEditUnidadeModal = false
    },
  },

  mounted() {
    this.getUnidades()
  },
}
</script>
