<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditPerfilModal
      v-if="showCreateEditPerfilModal"
      :perfilId="perfilIdSelected"
      @closeModal="closeCreateEditPerfilModal"
    />
    <PermissionsPerfilModal
      v-if="showPermissionsPerfilModal"
      :perfilId="perfilIdSelected"
      @closeModal="closePermissionsPerfilModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Perfil de acesso</h5>
        <div v-if="getModuleUserByCode('0.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditPerfil()"
          >
            Novo Perfil
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListPerfis
          :perfis="perfis"
          @editPerfil="createEditPerfil"
          @permissionsPerfil="permissionsPerfil"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import CreateEditPerfilModal from '@/components/administration/perfis/CreateEditPerfilModal.vue'
import PermissionsPerfilModal from '@/components/administration/perfis/PermissionsPerfilModal.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListPerfis from '@/components/administration/perfis/ListPerfis.vue'

export default {
  name: 'Perfis',

  components: {
    Layout,
    PageHeader,
    CreateEditPerfilModal,
    PermissionsPerfilModal,
    LoadingData,
    ListPerfis,
  },

  data() {
    return {
      title: 'Perfil',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Perfil',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditPerfilModal: false,
      showPermissionsPerfilModal: false,
      perfis: null,
      perfilIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getPerfis() {
      try {
        this.perfis = await this.$store.dispatch('getPerfis')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditPerfil(perfilId) {
      this.perfilIdSelected = perfilId || null
      this.showCreateEditPerfilModal = true
    },

    permissionsPerfil(perfilId) {
      this.perfilIdSelected = perfilId || null
      this.showPermissionsPerfilModal = true
    },

    closeCreateEditPerfilModal(loadListPerfis) {
      if (loadListPerfis) {
        this.getPerfis()
      }
      this.showCreateEditPerfilModal = false
    },

    closePermissionsPerfilModal() {
      this.showPermissionsPerfilModal = false
    },
  },

  mounted() {
    this.getPerfis()
  },
}
</script>
