<template>
  <b-modal
    id="modalCreateEditUnidade"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.descricao.$error }"
            placeholder="Descrição da unidade"
            v-model="$v.unidade.descricao.$model"
          />
          <span v-if="!$v.unidade.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.unidade.descricao.minLength" class="invalid-feedback">
            O nome do unidade deve possuir pelo menos 3 caracteres
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Endereço: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.endereco.$error }"
            placeholder="Rua, Av., Tv..."
            v-model="$v.unidade.endereco.$model"
          />
          <span v-if="!$v.unidade.endereco.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Nº: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.num_endereco.$error }"
            placeholder="Nº"
            v-model="$v.unidade.num_endereco.$model"
          />
          <span
            v-if="!$v.unidade.num_endereco.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Bairro:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.bairro.$error }"
            placeholder="Nome do bairro"
            v-model="$v.unidade.bairro.$model"
          />
          <span v-if="!$v.unidade.bairro.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.city_stateId.$error }"
            v-model="$v.unidade.city_stateId.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.city_id.$error }"
            v-model="$v.unidade.city_id.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span v-if="!$v.unidade.city_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>País:<span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.pais.$error }"
            placeholder="Digite o país"
            v-model="$v.unidade.pais.$model"
          />
          <span v-if="!$v.unidade.pais.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>CEP:<span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.cep.$error }"
            placeholder="00000-000"
            v-model="$v.unidade.cep.$model"
            :mask="'#####-###'"
            :masked="true"
          />
        </div>
        <span v-if="!$v.unidade.cep.required" class="invalid-feedback">
          O campo é obrigatório
        </span>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Responsável:</label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.unidade.user_id.$error }"
            v-model="$v.unidade.user_id.$model"
          >
            <option value="">Selecione uma turma</option>
            <option
              v-for="user in users"
              :key="user.id"
              :value="user.id"
              :title="`${user.name}`"
            >
              {{ user.name }}
            </option>
          </select>
          <span v-if="!$v.unidade.user_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{
              'is-invalid': $v.unidade.telefone.$error,
            }"
            placeholder="(00) 98888-8888"
            v-model="$v.unidade.telefone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.unidade.telefone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>
      <div class="form-row">
        <div v-if="unidadeId" class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="unidade.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="unidade.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditUnidadeModal',

  components: {
    TheMask,
  },

  props: {
    unidadeId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      unidade: {
        descricao: '',
        num_endereco: '',
        bairro: '',
        telefone: '',
        endereco: '',
        city_id: '',
        city_stateId: '',
        cep: '',
        pais: '',
        user_id: '',
      },
      cities: '',
      states: '',
      users: '',
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.unidadeId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'unidade.city_stateId': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.unidade.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.unidade.city_stateId,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidade() {
      try {
        this.unidade = await this.$store.dispatch('getUnidade', this.unidadeId)
        this.$bvModal.show('modalCreateEditUnidade')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.unidadeId) {
            response = await this.$store.dispatch('updateUnidade', {
              id: this.unidadeId,
              unidade: this.unidade,
            })
          } else {
            response = await this.$store.dispatch('saveUnidade', this.unidade)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    unidade: {
      descricao: {
        required,
        minLength: minLength(3),
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      endereco: {
        required,
      },
      num_endereco: {
        required,
      },
      bairro: {
        required,
      },
      city_id: {
        required,
      },
      city_stateId: {
        required,
      },
      cep: {
        required,
      },
      pais: {
        required,
      },
      user_id: {
        required,
      },
    },
  },

  mounted() {
    this.getStates()
    this.getUsers()
    if (this.unidadeId) {
      this.getUnidade()
    } else {
      this.$bvModal.show('modalCreateEditUnidade')
    }
  },
}
</script>
